console.log('Vite ⚡️ Rails');

import '@hotwired/turbo-rails';

import 'simplebar/dist/simplebar';
import 'simplebar/dist/simplebar.css';
import 'toastify-js/src/toastify.css';

import '../controllers';

import feather from 'feather-icons';
feather.replace({ width: '1em', height: '1em' });
import '../styles/custom.css';
