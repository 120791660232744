import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    const form = this.element;
    form.querySelectorAll('input, select').forEach((input) => {
      input.addEventListener('change', () => {
        form.requestSubmit();
      });
    });
  }
}
