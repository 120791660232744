import { Controller } from '@hotwired/stimulus';
import Toastify from 'toastify-js';

export default class extends Controller {
  connect() {
    const flash = JSON.parse(this.element.dataset.flashJson);
    flash.forEach((f) => {
      const background =
        f[0] == 'success'
          ? 'linear-gradient(to right, #00b09b, #96c93d)'
          : 'linear-gradient(to right, #ff5f6d, #ffc371)';
      Toastify({
        text: f[1],
        duration: 3000,
        // destination: 'https://github.com/apvarun/toastify-js',
        newWindow: true,
        close: true,
        gravity: 'top', // `top` or `bottom`
        position: 'right', // `left`, `center` or `right`
        stopOnFocus: true, // Prevents dismissing of toast on hover
        style: {
          background: background,
        },
        onClick: function () {}, // Callback after click
      }).showToast();
    });
  }
}
